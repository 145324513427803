import React, {useEffect, useState} from 'react'
import useAxios from "../hooks/axios.hook";
import {BaseURL, BOBaseURL} from "../constant";
import moment from "moment";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Paper,
    Snackbar,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Alert, Typography, TableBody
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import Loading from "../components/loading";
import SportSelect from '../components/sportSelect';

const FreeEventRow = React.memo(({event, index, onCheck}) => {

    const _onCheck = () => {
        onCheck(index)
    }

    return (
        <TableRow>
            <TableCell>{event.name}</TableCell>
            <TableCell>{moment(event.startAt).format('HH:mm')}</TableCell>
            <TableCell>{event.tournament ? event.tournament.displayName : '-'}</TableCell>
            <TableCell>{event.league}</TableCell>
            <TableCell>
                <Checkbox checked={event.restricted} onClick={_onCheck}/>
            </TableCell>
        </TableRow>
    )
})

const DatePicker = React.memo(({active, onChange}) => {
    const items = []

    for (let i = 0; i <= 5; i++) {
        let _date = moment().add(i, 'day')
        items.push(
            <Box
                onClick={() => onChange(_date)}
                key={i} sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                color: active.format('DD') === _date.format('DD') ? 'primary.main' : '',
                ":hover": {
                    backgroundColor: 'grey.300',
                },
                cursor: 'pointer',
            }}>
                <Typography>{_date.format('ddd')}.</Typography>
                <Typography>{_date.format('DD')}</Typography>
                {active.format('DD') === _date.format('DD') && <Box sx={{width: '100%', height: '5px', backgroundColor: 'primary.main', borderTopLeftRadius: 5, borderTopRightRadius: 5}} />}
            </Box>
        )
    }

    return (
        <Paper sx={{display: 'flex', my: 2}}>
            {items}
        </Paper>
    )
})

export default function RestrictedEventPage({}) {
    const [snackOpen, setSnackOpen] = useState(false)

    const [events, setEvents] = useState([])
    const [sport, setSport] = useState('tennis')
    const [date, setDate] = useState(moment())

    const {data, loading, error, request} = useAxios({url: `${BOBaseURL}restricted-event/${sport}/events?date=${date.format('YYYY-MM-DD')}`, lazy: true, method: 'get'})
    const {data: updated, loading: updateLoading, error: updateError, request: update} = useAxios({url: `${BOBaseURL}restricted-event/${sport}`, method: 'POST'})

    useEffect(() => {
        request(null, null, `${BOBaseURL}restricted-event/${sport}/events?date=${date.format('YYYY-MM-DD')}`)
    }, [sport, date])

    useEffect(() => {
        if (data) setEvents(data)
    }, [data])

    useEffect(() => {
        if (updated || updateError) setSnackOpen(true)
    }, [updated, updateError])

    const onCheck = (index) => {
        setEvents(
            events.map((elem, _index) => {
                if (_index === index) elem.restricted = !elem.restricted
                return elem
            })
        )
    }

    const onSubmit = () => {
        update({}, {
            date: date.format('YYYY-MM-DD'),
            events: events.filter(event => event.restricted)
        })
    }

    return (
        <>
            <Snackbar open={snackOpen} onClose={() => setSnackOpen(false)}  autoHideDuration={6000}>
                {updateError ?
                    <Alert onClose={() => setSnackOpen(false)} security={'error'}>
                        Error during the set free event.
                    </Alert>
                    :
                    <Alert onClose={() => setSnackOpen(false)} severity={'success'}>
                        {updated?.count} events set restricted with success.
                    </Alert>
                }
            </Snackbar>

            <SportSelect setSport={setSport} sport={sport} />

            <DatePicker active={date} onChange={setDate} />
            <Paper>
                <Loading loading={loading}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Start at</TableCell>
                                <TableCell>Tournament</TableCell>
                                <TableCell>League</TableCell>
                                <TableCell>Restricted</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {events?.map((event, index) => <FreeEventRow event={event} index={index} onCheck={onCheck} key={index} /> )}
                        </TableBody>
                    </Table>
                </Loading>
            </Paper>

            <Box sx={{display:  'flex', justifyContent: 'end', my: 2}}>
                <LoadingButton onClick={onSubmit} loading={updateLoading} variant={'contained'}>Submit</LoadingButton>
            </Box>
        </>
    )
}