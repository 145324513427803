import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import React, {useState} from "react";
import {
    Box,
    IconButton, Menu, MenuItem, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TextField
} from "@mui/material";
import moment from "moment";
import GiveUserAccess from "../dialogs/giveUserAccess";
import {MoreVert, Search} from "@mui/icons-material";
import EditUserAccess from "../dialogs/editUserAccess";

export default function Users({}) {
    const {loading, data, request} = useAxios({url: `${BOBaseURL}user`, query: {take: 10, skip: 0}})

    const [email, setEmail] = useState('')
    const [users, setUsers] = useState([])

    const [page, setPage] = useState(0)

    const [freeAccess, setFreeAccess] = useState(false)
    const [editAccess, setEditAccess] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [selected, setSelected] = useState()

    const onMenu = (event, user) => {
        setAnchorEl(event.currentTarget)
        setSelected(user)
    }

    const onClose = () => {
        setAnchorEl(null)
    }

    const renderMenu = () => {
        return (
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={onClose}
            >
                <MenuItem onClick={() => setFreeAccess(true)}>Give free Access</MenuItem>
                <MenuItem onClick={() => setEditAccess(true)}>Edit Access</MenuItem>
            </Menu>
        )
    }

    const onPageChange = (event, newPage) => {
        setPage(newPage)
        if (newPage > 0) {
            request({take: 10, skip: 10 * newPage, email})
        } else {
            request({take: 10, skip: 0, email})
        }
    }

    const onEmailChange = ({target: {value}}) => {
        setEmail(value)
    }

    const onSearch = () => {
        if (email && email.length >= 3) {
            request({take: 10, skip: 0, email})
            setUsers([])
            setPage(0)
        } else {
            request({take: 10, skip: 0})
            setUsers([])
            setPage(0)
        }
    }

    const onKeyPress = ({key}) => {
        if (key === 'Enter') onSearch()
    }

    const onModalClose = () => {
        setFreeAccess(false)
        if (page > 0) {
            request({take: 10, skip: 10 * page, email})
        } else {
            request({take: 10, skip: 0, email})
        }
    }

    const onCloseEdit = () => {
        setEditAccess(false)
    }

    const renderUserRow = (user) => {
        const packageAccess = user.packageAccess[0]

        return (
            <TableRow key={user.id}>
                <TableCell>{moment(user.createdAt).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{user.email ?? user.name}</TableCell>
                <TableCell>{packageAccess?.active ? 'Yes' : 'No'}</TableCell>
                <TableCell>{packageAccess?.free ? 'Yes' : 'No'}</TableCell>
                <TableCell>{packageAccess?.expiredDate ? moment(packageAccess.expiredDate).format('YYYY-MM-DD') : '-'}</TableCell>
                <TableCell>{packageAccess && !packageAccess.free ? (packageAccess?.Iaps?.length ? packageAccess.Iaps[0].platform : 'Strip') : '-'}</TableCell>
                <TableCell>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        onClick={(e) => onMenu(e, user)}>
                        <MoreVert />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', my: 2}}>
                <TextField onKeyPress={onKeyPress} variant={'outlined'} value={email} onChange={onEmailChange} label={'email'} />
                <IconButton onClick={onSearch}><Search /></IconButton>
            </Box>

            {renderMenu()}
            <GiveUserAccess user={selected} onClose={onModalClose} open={freeAccess} />
            <EditUserAccess user={selected} onClose={onCloseEdit} open={editAccess} />
            <TableContainer component={Paper}>
                <Table size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>CreatedAt</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Access</TableCell>
                            <TableCell>Free</TableCell>
                            <TableCell>Expired</TableCell>
                            <TableCell>Platform</TableCell>
                            <TableCell size='small' />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data?.users?.map(renderUserRow)}
                    </TableBody>
                </Table>

                {data?.count && <TablePagination  count={data.count} page={page} onPageChange={onPageChange} rowsPerPage={10} />}
            </TableContainer>
        </React.Fragment>
    )
}