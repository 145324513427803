import React from 'react'
import {Box, Container, Typography} from "@mui/material";
import Activity from "../components/analytics/activity";
import NewActivity from "../components/analytics/newActivity";

export default function AnalyticsPage({}) {
    return (
        <Container>
            <Box sx={{marginBottom: 4}}>
                <Typography variant={'h4'} sx={{marginBottom: 2}}>Activities</Typography>
                <Activity />
            </Box>

            <Box sx={{marginBottom: 4}}>
                <Typography variant={'h4'} sx={{marginBottom: 2}}>New users</Typography>
                <NewActivity />
            </Box>
        </Container>
    )
}