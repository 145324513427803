import React, { Fragment, useState } from 'react'
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Modal } from "@mui/material";
import { useEffect } from 'react';
import useAxios from '../../hooks/axios.hook';
import { BaseURL, BOBaseURL } from '../../constant';
import OfferCard from './offerCard';
import Loading from '../loading';
import moment from 'moment'

export default function OfferModal({ open, setOpen, onAddOffer }) {
  const [selected, setSelected] = useState({ sport: '', match: '', type: '', input: '' })
  const { data, request, loading } = useAxios({ lazy: true })
  const { data: cData, request: cRequest, loading: cLoading } = useAxios({ lazy: true })

  useEffect(() => {
    if (selected.sport)
      request({ state: 'SCHEDULED' }, null, `${BaseURL}sport/${selected.sport}/events`)
  }, [selected.sport])

  useEffect(() => {
    if (selected.match)
      cRequest({}, null, `${BOBaseURL}tips/${selected.sport}/${selected.match.id}/offers`)
  }, [selected.match])

  const onAdd = (off) => {
    setOpen(false)
    console.log(off)
    onAddOffer(off)
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={{ overflowY: 'scroll', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, height: '80%', backgroundColor: 'white', border: '2px solid #000', boxShadow: 24, p: 4, }}>

        <Typography sx={{ marginBottom: 2 }} variant="h6" component="h2">Choisir un sport</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Sport</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected.sport}
            label="Sport"
            onChange={(e) => setSelected({ ...selected, sport: e.target.value })}
          >
            <MenuItem value={'tennis'}>Tennis</MenuItem>
            <MenuItem value={'soccer'}>Soccer</MenuItem>
            <MenuItem value={'basketball'}>Basketball</MenuItem>
          </Select>
        </FormControl>

        <Typography sx={{ marginY: 2 }} id="modal-modal-title" variant="h6" component="h2">Choisir un match</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Match</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected.match}
            label="Sport"
            onChange={(e) => setSelected({ ...selected, match: e.target.value })}
          >
            {data && data?.map((elem, index) => {
              return (
                <MenuItem key={index} value={elem}>
                  <div>
                    <div>{elem.name}</div>
                    <div>{moment(elem.startAt).format('MM-DD HH:mm')}</div>
                  </div>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Typography sx={{ marginY: 2 }} id="modal-modal-title" variant="h6" component="h2">Choisir une offer</Typography>
        <Box sx={{ display: 'flex', gap: 5 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected.type}
              label="Type"
              onChange={(e) => setSelected({ ...selected, type: e.target.value })}
            >
              {cData && cData?.map((elem, index) => <MenuItem key={index} value={elem.type}>{elem.type}</MenuItem>)}
            </Select>
          </FormControl>
          <TextField sx={{ marginBottom: 2 }} size='medium' fullWidth value={selected.input} onChange={({ target: { value } }) => setSelected({ ...selected, input: value })} />
        </Box>

        <Box>
          <Loading loading={cLoading}>
            {cData && cData?.filter(elem => selected.type ? elem.type === selected.type : elem).map(elem => {
              return (
                <Fragment>
                  <Typography sx={{ marginTop: 3 }} variant='h5'>{elem.type}</Typography>
                  {elem.offers.filter(off => selected.input.length ? off.label.fr.includes(selected.input) : off).map((off, index) =>
                    <OfferCard key={index} onAddOffer={(off) => onAdd(off)} off={{...off, event: selected.match, market: elem, sport: selected.sport}} />
                  )}
                </Fragment>
              )
            })}
          </Loading>
        </Box>
      </Box>
    </Modal>
  )
}
