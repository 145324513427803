import React, {useEffect, useState} from 'react'
import {Card} from "@mui/material";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import Loading from "../loading";
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import moment from "moment";


export default function NewActivity({}) {
    const [nbDays, setNbDays] = useState(10)
    const {data, loading, error} = useAxios({url: `${BOBaseURL}analytics/activity/new`, method: 'get', query: {nbDays}})
    const [chartData, setChartData] = useState([])

    useEffect(() => {
        if (data) {
            setChartData(Object.keys(data).reduce((res, day) => {
                res.push({date: moment(day).format('MM-DD'), newUser: data[day]})
                return res
            }, []))
        }
    }, [data])


    return (
        <React.Fragment>
            <Card sx={{p: 1}}>
                <Loading loading={loading || !data}>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart  data={chartData}>
                            <Line type="linear" dataKey="newUser" stroke="#877486" />
                            <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, .15)" />
                            <XAxis dataKey="date" />
                            <Tooltip />
                            <Legend />
                            <YAxis />
                        </LineChart>
                    </ResponsiveContainer>
                </Loading>
            </Card>
        </React.Fragment>
    )
}