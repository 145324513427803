import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select, TextField,
    Typography
} from "@mui/material";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import {LoadingButton} from "@mui/lab";
import moment from "moment";

function RenderDialog({open, onClose}) {
    const [sport, setSport] = useState('BASKETBALL')
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const {request, data, loading} = useAxios({url: `${BOBaseURL}crawler/sport/${sport}/${date}`, method: 'post'})

    const onSubmit = () => {
        request()
    }

    const onSelectDate = ({target: {value}}) => {
        setDate(value)
    }

    useEffect(() => {
        if (data) onClose()
    }, [data])

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Crawl market</DialogTitle>

            <DialogContent dividers>
                <Select fullWidth value={sport} onChange={({target: {value}}) => setSport(value)}>
                    <MenuItem value={"BASKETBALL"}>Basketball</MenuItem>
                    <MenuItem value={"SOCCER"}>Soccer</MenuItem>
                    <MenuItem value={"TENNIS"}>Tennis</MenuItem>
                </Select>

                <TextField fullWidth type="date" onChange={onSelectDate} value={date}/>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>CANCEL</Button>
                <LoadingButton variant={'contained'} loading={loading} onClick={onSubmit}>RUN</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default function CrawlEvents({}) {
    const [open, setOpen] = useState(false)

    return (
        <Card sx={{m: 1}}>
            <RenderDialog open={open} onClose={() => setOpen(false)} />

            <CardContent>
                <Typography>Get Events</Typography>
                <div>
                    <Button onClick={() => setOpen(true)} color={"primary"} variant={'contained'}>Run</Button>
                </div>
            </CardContent>
        </Card>
    )
}