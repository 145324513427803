import React, {useEffect, useState} from 'react'

export const Context = React.createContext(null)

export default function TokenContext({children}) {
    const [token, setToken] = useState(localStorage.getItem('token') || null)

    useEffect (() => {
      localStorage.setItem('token', token);
    }, [token])

    return (
        <Context.Provider value={{token, setToken}}>
            {children}
        </Context.Provider>
    )
}

export const useTokenContext = () => (React.useContext(Context))