import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import useAxios from '../../hooks/axios.hook';
import { BOBaseURL } from '../../constant';
import MomentUtils from '@date-io/moment'
import {LoadingButton} from "@mui/lab";
require('moment-timezone')

export default function ChangeEventStart({event, sport}) {
    const [value, setValue] = useState(new Date(event.startAt))
    const [newDate, setNewDate] = useState(null)
    const { loading: startLoading, request: startRequest, data: startData } = useAxios({ method: 'post', lazy: true })

    useEffect(() => {
        if (startData) setNewDate(null)
    }, [startData])

    return (
        <Box sx={{width: '100%', display: 'flex',  paddingY: 1, gap: 2, alignItems: 'center'}}>
            <LocalizationProvider dateAdapter={MomentUtils}>
                <DateTimePicker
                    label="Start changer"
                    value={value}
                    ampm={false}
                    onAccept={(val) => setNewDate(val)}
                    onChange={(value) => setValue(value)}
                    inputFormat={"DD/MM/YY-HH:mm"}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <LoadingButton onClick={() => startRequest(null, {start: newDate}, `${BOBaseURL}sport/${sport}/event/${event.id}/start`)} sx={{height: 40}} color={"primary"} disabled={!newDate} variant={'contained'} loading={startLoading}>Update</LoadingButton>
        </Box>
    )
}