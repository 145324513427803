import React, {Fragment, useState} from 'react'
import {Box, Container, Typography, Paper, Button, FormControl, InputLabel, Select, MenuItem, TextField, Modal} from "@mui/material";

export default function OfferCard({off, onAddOffer, onDeleteOffer, adding=true}) {

    function getColor(book) {
        if (book === 'unibet.fr')
            return 'green'
        if (book === 'winamax.fr')
            return 'red'
        if (book === 'parionssport.fr')
            return 'blue'
        if (book === 'betclic.fr')
            return 'yellow'
    }

    return (
        <Box sx={{display: 'flex', gap: 5, padding: 2, backgroundColor: '#BABABA', marginTop: 2, borderRadius: 2, justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', gap: 2, justifyContent: 'space-between', width: '100%'}}>
                <Box>
                    <Box sx={{display: 'flex', gap: 1}}>
                        <Typography>{off.type}</Typography>
                        <Typography>{off.value}</Typography>
                    </Box>
                    <Typography>{off.label.fr}</Typography>
                </Box>
                <Box>
                    <Typography sx={{fontWeight: 'bold'}}>{off.odd}</Typography>
                    <Typography sx={{color: getColor(off.bookmakerId)}}>{off.bookmakerId}</Typography>
                </Box>
            </Box>
            {adding ? <Button variant='contained' onClick={() => onAddOffer(off)}>Ajouter</Button>
            : <Button variant='contained' color='error' onClick={() => onDeleteOffer(off.id)}>Supprimer</Button>}
        </Box>
    )
}
