import React, {useState} from 'react'
import {
    AppBar,
    Box,
    Button, Drawer,
    IconButton,
    Toolbar, useTheme,
} from "@mui/material";
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavigationMenuList from './components/navigationMenuList';
import { getAuth, signOut } from "@firebase/auth";
import { useColorContext } from './components/styles/style';
import GreySwitch from './components/styles/switch';

function useIsWidthUp(breakpoint) {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(breakpoint));
}

const drawerWidth = 210;


const label = { inputProps: { 'aria-label': 'Switch demo' } };

function AppLayout({children, width}) {
    const theme = useTheme()
    const auth = getAuth();
    const [open, setOpen] = useState(true)
    const {mode, setMode} = useColorContext();
    const isWidthUp = useIsWidthUp("sm");
    function handleChange () {
        setMode(mode === 'dark' ? 'light' : 'dark');
    };

    return (
        <Box sx={{
            display: 'flex',
            gap: 2,
            height: 'calc(100vh - 20px)',
            margin: '10px'
        }}>
            {/*<AppBar position="fixed" style={{backgroundColor: theme.palette.background.paper}}*/}
            {/*        className={classes.appBar} >*/}
            {/*    <Toolbar className={classes.toolbar}>*/}
            {/*        <IconButton onClick={() => setOpen(!open)}>*/}
            {/*            <Menu/>*/}
            {/*        </IconButton>*/}
            {/*        <Box flexGrow="1"/>*/}
            {/*        <GreySwitch checked={mode === 'light' ? true : false} onChange={handleChange}/>*/}
            {/*        <Button onClick={() => {*/}
            {/*            signOut(auth)*/}
            {/*            }}>*/}
            {/*                logOut*/}
            {/*        </Button>*/}
            {/*    </Toolbar>*/}
            {/*</AppBar>*/}
            <Box
                sx={{
                    height: '100%',
                    borderRadius: 2,
                    backgroundColor: 'background.contrast.main',
                    color: 'background.contrast.contrastText',
                }}>
                <NavigationMenuList />
            </Box>

            <Box
                sx={{
                    width: '100%',
                }}>
                {children}
            </Box>
        </Box>
    )
}
export default (AppLayout)