import React, {createContext, useEffect} from 'react'
import {ThemeProvider, createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { CssBaseline } from '@mui/material';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export default function ToggleColorMode({children}) {
    const [mode, setMode] = React.useState(localStorage.getItem('colorMode') || 'dark');
    const palette = {
        mode,
        ...(mode === 'light'
            ? {
                // palette values for light mode
                primary: grey,
                divider: 'rgba(0, 0, 0, 0.12)',
                background: {
                    default: '#fff',
                    paper: '#fff',
                },
                action: {
                    active: 'rgba(0, 0, 0, 0.54)',
                    disabled: 'rgba(0, 0, 0, 0.26)',
                    hover: 'rgba(0, 0, 0, 0.04)',
                    disabledBackground: 'rgba(0, 0, 0, 0.12)',
                    selected: 'rgba(0, 0, 0, 0.08)',
                },
                text: {
                    primary: 'rgba(0, 0, 0, 0.87)',
                    secondary: 'rgba(0, 0, 0, 0.6)',
                    disabled: 'rgba(0, 0, 0, 0.38)',
                },
            }
            : {
                // palette values for dark mode
                primary: grey,
                divider: 'rgba(255, 255, 255, 0.12)',
                background: {
                    default: '#121212',
                    paper: '#121212',
                },
                action: {
                    active: '#fff',
                    disabled: 'rgba(255, 255, 255, 0.3)',
                    hover: 'rgba(255, 255, 255, 0.08)',
                    disabledBackground: 'rgba(255, 255, 255, 0.12)',
                    selected: 'rgba(255, 255, 255, 0.16)',
                },
                text: {
                    primary: '#fff',
                    secondary: 'rgba(255, 255, 255, 0.7)',
                    disabled: 'rgba(255, 255, 255, 0.5)',
                },
            }),
        google: "#DB4437",
        facebook: "#4267B2",
    }

    useEffect (() => {
        console.log("change color")
        localStorage.setItem('colorMode', mode);
      }, [mode])

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: palette,
                components: {
                    MuiSwitch: {
                        variants: [
                            {
                                props: { variant: 'displayMode' },
                                style: {
                                    color: '#fff',
                                },
                            },
                            {
                                props: { variant: 'active' },
                                style: {
                                    textTransform: 'none',
                                },
                            },
                        ],
                    },
                    MuiButton: {
                        variants: [
                            {
                                props: { variant: 'discret' },
                                style: {
                                    bgcolor: palette.primary.action,
                                },
                            },
                            {
                                props: { variant: 'google' },
                                style: {
                                    bgcolor: palette.google,
                                },
                            },
                            {
                                props: { variant: 'facebook' },
                                style: {
                                    bgcolor: palette.google.facebook,
                                },
                            },
                        ],
                    },
                },
                typography: {
                    button: {
                        fontSize: '1rem',
                    },

                },
            }),
        [mode],
    );

    return (
        <ColorModeContext.Provider value={{mode, setMode}}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export const useColorContext = () => (React.useContext(ColorModeContext))