import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TokenContext from './context/token.context';
import reportWebVitals from './reportWebVitals';


//const [isConnected, setIsConnected] = useState(true)
//
//useEffect(() => {
//  //TODO si deconnecter
//}, [isConnected]) 


ReactDOM.render(
  <React.StrictMode>
    <TokenContext>
      <App/>
    </TokenContext>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
