import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRefetchContext } from "../context/refetch.context";
import { useTokenContext } from "../context/token.context";
import { getAuth } from "@firebase/auth";
import { BaseURL, BOBaseURL } from "../constant";

const baseURL = BaseURL;
const BObaseURL = BOBaseURL;

//const baseURL = process.env.API_URL || 'http://localhost:3111';

export const REQUEST_METHOD = {
  PUT: "put",
  GET: "get",
  POST: "post",
  DELETE: "delete",
};

export const REQUEST_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
};

export const useRequest = (
  url,
  method = REQUEST_METHOD.GET,
  params = {},
  body = {},
  boApi = false
) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { toRefetch } = useRefetchContext();
  //   const { token, setToken } = useTokenContext();
  const auth = getAuth();

  const fetch = async (params, body) => {
    setLoading(true);
    // const tempToken = await auth.currentUser.getIdToken();
    // if (tempToken && tempToken != token) setToken(tempToken);
    axios({
      baseURL: boApi ? BObaseURL : baseURL,
      method,
      //   headers: { "access-token": tempToken },
      url,
      params,
      data: body,
    })
      .then((response) => {
        setLoading(false);
        if (response.status !== 200) {
          setData({ status: REQUEST_STATUS.ERROR });
        } else {
          setData({ status: REQUEST_STATUS.SUCCESS, data: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setData({ status: REQUEST_STATUS.ERROR });
      });
  };

  useEffect(() => {
    if (
      toRefetch &&
      Array.isArray(toRefetch) &&
      toRefetch.find((elem) => elem === url) &&
      method === REQUEST_METHOD.GET
    ) {
      fetch(params, body);
    }
  }, [toRefetch]);

  useEffect(() => {
    fetch();
  }, []);

  return { refetch: fetch, loading, response: data };
};

export const useLazyRequest = (
  url,
  method = REQUEST_METHOD.GET,
  boApi = false
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const { toRefetch } = useRefetchContext();

  const [params, setParams] = useState(null); //used for the toReload event
  const [body, setBody] = useState(null); //used for the toReload event
  const { token, setToken } = useTokenContext();
  const auth = getAuth();

  const request = async (_params = {}, _body = {}) => {
    if (_params !== params) setParams(_params);
    if (_body !== body) setBody(_body);

    setLoading(true);
    setData(undefined);
    // const tempToken = await auth.currentUser.getIdToken()
    // if (tempToken && tempToken != token)
    //     setToken(tempToken)

    axios({
      baseURL: boApi ? BObaseURL : baseURL,
      url,
      //   headers: {
      //     "access-token": tempToken,
      //   },
      method,
      params: _params,
      data: _body,
    })
      .then((response) => {
        setLoading(false);
        if (response.status !== 200) {
          setData({ status: REQUEST_STATUS.ERROR });
          setData(response.data);
        } else {
          setData({ status: REQUEST_STATUS.SUCCESS, data: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setData({ status: REQUEST_STATUS.ERROR });
      });
  };

  useEffect(() => {
    if (
      data &&
      toRefetch &&
      Array.isArray(toRefetch) &&
      toRefetch.find((elem) => elem === url) &&
      method === REQUEST_METHOD.GET
    ) {
      request(params, body);
    }
  }, [toRefetch]);

  return { request, loading, response: data };
};
