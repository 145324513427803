import React, {useEffect, useState} from 'react'
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    TextField,
    Typography,
    Switch
} from "@mui/material";
import moment from "moment";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";

export default function EditUserAccess({user, open, onClose}) {
    const {request, loading, data} = useAxios({url: `${BOBaseURL}user/access/active`, method: 'post'})
    const [checked, setChecked] = useState(false)
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(() => {
        if (data) {
            onClose()
            setChecked(false)
        }
    }, [data])

    const onRequest = () => {
        request({}, {active: checked, userId: user.id})
    }

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} >
            <DialogTitle>Edit access</DialogTitle>

            <DialogContent dividers={true}>
                <Box sx={{display: 'grid'}}>
                    <Typography variant={'caption'}>User</Typography>
                    <Typography>{user?.email ?? user?.name}</Typography>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                    <Typography variant={'caption'}>User package access :</Typography>
                    <Switch {...label} value={checked} onChange={(e) => setChecked(e.target.checked)} />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant={'contained'} onClick={onRequest}>Edit Package Access</Button>
            </DialogActions>
        </Dialog>
    )
}