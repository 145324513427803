import React from 'react'
import {Box, Container, Typography} from "@mui/material";
import ActiveAccessCount from "../components/metrics/activeAccessCount";
import CrawlEvents from "../components/action/crawlEvents";
import CrawlMarkets from "../components/action/crawlMarkets";
import SendFreeAccess from "../components/action/sendFreeAccess";
import ClearUnmapped from "../components/action/clearUnmapped";

export default function DashboardPage({}) {
    return (
        <Container>
            <Box sx={{marginBottom: 3}}>
                <Typography variant={'h5'}>Metrics</Typography>
                <Box sx={{display: 'flex'}}>
                    <ActiveAccessCount />
                </Box>
            </Box>

            <Box>
                <Typography variant={'h5'}>Actions</Typography>
                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <CrawlEvents />
                    <CrawlMarkets />
                    <SendFreeAccess />
                    <ClearUnmapped/>
                </Box>
            </Box>
        </Container>
    )
}