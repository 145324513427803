import React from 'react'
import {Box, Card, Typography} from "@mui/material";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import Loading from "../loading";

export default function ActiveAccessCount({sx}) {
    const {loading, data} = useAxios({url: `${BOBaseURL}user/access/count`, method: 'get'})

    return (
        <Card sx={{width: 'fit-content', padding: 2}}>
            <Loading loading={loading}>
                <Typography variant={'caption'}>Active subscription</Typography>
                <Typography variant={'h4'}>{data}</Typography>
            </Loading>
        </Card>
    )
}