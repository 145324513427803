import React, {useEffect, useState} from 'react'
import {
    Button,
    Card,
    CardContent,
    Typography
} from "@mui/material";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import {LoadingButton} from "@mui/lab";

export default function ClearUnmapped({}) {
    const [open, setOpen] = useState(false)
    const {request, data, loading} = useAxios({url: `${BOBaseURL}mapping`, method: 'delete'})


    const onSubmit = () => {
        request()
    }
    return (
        <Card sx={{m: 1}}>
            <CardContent>
                <Typography>Clear Unmapped</Typography>
                    <LoadingButton onClick={onSubmit} color={"primary"} variant={'contained'} loading={loading}>Run</LoadingButton>
            </CardContent>
        </Card>
    )
}