import {Container, Tabs, Tab, Box, Typography, Divider, ButtonGroup, Button} from "@mui/material";
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MappingTable from "../components/mappingTable";
import UnMappedSearch from "../components/unMapped.search";
import { useViewport } from "../context/viewport.context";


export default function Mapping () {
    const [type, setType] = useState("team");
    const defaultUnmapped = { id: "", name: "", createdAt: "", type: "", sport: "", source: ""}
    const [unmapped, setUnmapped] = useState(defaultUnmapped);
    const [needToRefetch, setNeedToRefetch] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false);
    const theme = useTheme();
    const [sport, setSport] = useState('');
    const { width } = useViewport();
    const breakpoint = 900;


    const handleChangeType = (event, newValue) => {
        if (!loadingTable)
            setType(newValue);
    };

    useEffect(() => {
        console.log(width)
    }, [width])

    useEffect(() => {
        setUnmapped(defaultUnmapped)
    }, [type])

    const handelClick = (type) => {
        switch (type) {
            case 'BASKETBALL':
                setSport(type)
                break;
            case 'TENNIS':
                setSport(type)
                break;
            case 'SOCCER':
                setSport(type)
                break;
            default:
                setSport('')
                break;
        }
    }

    const buttons = [
        <Button disabled={sport === 'BASKETBALL'} onClick={() => handelClick('BASKETBALL')} key="BASKETBALL">Basketball</Button>,
        <Button disabled={sport === 'TENNIS'} onClick={() => handelClick('TENNIS')} key="TENNIS">Tennis</Button>,
        <Button disabled={sport === 'SOCCER'} onClick={() => handelClick('SOCCER')} key="SOCCER">Soccer</Button>,
        <Button disabled={sport === ''} onClick={() => handelClick('ALL')} key="ALL">All</Button>,
      ];

    return (width > breakpoint ? 
        <Container sx={{ display: 'flex', flexDirection: 'row' }}>
            <Container sx={{ display: 'flex', flexDirection: 'column', flex: 6 }}>
                <Typography sx={{ py: 2, color: theme.palette.text.primary }} variant="h4">Mapping</Typography>

                <ButtonGroup size="large" aria-label="large button group">
                    {buttons}
                </ButtonGroup>

                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={type}
                        onChange={handleChangeType}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        <Tab value="team" label="team" />
                        <Tab value="player" label="player" />
                        <Tab value="event" label="event" />
                    </Tabs>
                </Box>
                <MappingTable sport={sport} type={type} onLoading={setLoadingTable} setSelected={setUnmapped} needRefetch={needToRefetch} setNeedRefetch={setNeedToRefetch} />
            </Container>
            <Divider orientation="vertical" flexItem />
            <Container sx={{ display: 'flex', py: 2, flex: 3 }}>
                <UnMappedSearch sport={sport} unMapped={unmapped} type={type} setLoading={setLoadingTable} setNeedRefetch={setNeedToRefetch} />
            </Container>
        </Container>
        :
        <Container sx={{ display: 'flex', flexDirection: 'row' }}>
            <Container sx={{ display: 'flex', flexDirection: 'column', flex: 6 }}>
                <Typography sx={{ py: 2, color: theme.palette.text.primary }} variant="h4">Mapping</Typography>

                <UnMappedSearch sport={sport} unMapped={unmapped} type={type} setLoading={setLoadingTable} setNeedRefetch={setNeedToRefetch} />
                <Divider flexItem />

                <ButtonGroup size="large" aria-label="large button group">
                    {buttons}
                </ButtonGroup>

                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={type}
                        onChange={handleChangeType}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        <Tab value="team" label="team" />
                        <Tab value="player" label="player" />
                        <Tab value="event" label="event" />
                    </Tabs>
                </Box>
                <MappingTable sport={sport} type={type} onLoading={setLoadingTable} setSelected={setUnmapped} needRefetch={needToRefetch} setNeedRefetch={setNeedToRefetch} />
            </Container>
        </Container>)
}