import React, { useEffect, useState } from 'react'
import { Paper, Typography, Box, TextField, RadioGroup, FormControlLabel, Radio, Button, Table, TableHead, TableBody, TableRow, TableCell, Pagination, IconButton, MenuItem, Menu, LinearProgress } from '@mui/material'
import LeagueSelect from '../components/leagueSelect'
import useAxios from '../hooks/axios.hook'
import { BOBaseURL } from '../constant'
import moment from 'moment'
import Loading from '../components/loading'
import { MoreVert } from '@mui/icons-material'
import ChangeEventStart from '../components/action/changeEventStart'

export default function Events({ }) {
    const [index, setIndex] = useState(1)
    const [params, setParams] = useState({ sport: 'tennis', name: '', date: '', state: 'all', league: 'all', take: 25 })
    const [locks, setLocks] = useState([])

    const { data, request, loading } = useAxios({ lazy: true })
    const { data: updateData, request: updateRequest, loading: updateLoading } = useAxios({ method: 'post', lazy: true })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [event, setEvent] = useState()

    const onKeyPress = (event) => {
        if (event.key === 'Enter') onSearch()
    }

    const onSearch = (index = 1) => {
        request({
            name: params.name,
            date: params.date,
            state: params.state === 'all' ? '' : params.state,
            league: params.league === 'all' ? '' : params.league,
            take: params.take,
            skip: (index - 1) * params.take
        }, null, `${BOBaseURL}sport/${params.sport}/events`)
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress)
        return () => document.removeEventListener('keydown', onKeyPress)
    }, [params])

    const onPageChange = (_, index) => {
        setIndex(index)
        onSearch(index)
    }

    const onMenu = (e, event) => {
        setAnchorEl(e.currentTarget)
        setEvent(event)
    }

    const onClose = () => {
        setEvent(undefined)
        setAnchorEl(undefined)
    }

    const onCancelEvent = () => {
        onClose()
        if (!event?.id) return
        updateRequest(null, {state: 'CANCELLED'}, `${BOBaseURL}sport/${params.sport}/event/${event.id}`)
        setLocks([...locks, event.id])
    }

    const renderMenu = () => {
        return (
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={onClose}
            >
                <MenuItem disabled={event?.state !== 'SCHEDULED'} onClick={onCancelEvent} >Cancel event</MenuItem>
            </Menu>
        )
    }

    return (
        <>
            <>
                <Paper sx={{ p: 2, my: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                        <Typography >Sport</Typography>
                        <RadioGroup row onChange={({ target: { value } }) => setParams({ ...params, league: 'all', sport: value })} value={params.sport}>
                            <FormControlLabel value='tennis' control={<Radio />} label='Tennis' />
                            <FormControlLabel value='basketball' control={<Radio />} label='Basketball' />
                            <FormControlLabel value='soccer' control={<Radio />} label='Soccer' />
                        </RadioGroup>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography >Name</Typography>
                            <TextField size='small' fullWidth value={params.name} onChange={({ target: { value } }) => setParams({ ...params, name: value })} />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography>Date</Typography>
                            <TextField size='small' type='date' fullWidth value={params.date} onChange={({ target: { value } }) => setParams({ ...params, date: value })} />
                        </Box>
                    </Box>

                    <Box>
                        <Typography>League</Typography>
                        <LeagueSelect sport={params.sport} value={params.league} setLeague={league => setParams({ ...params, league })} />
                    </Box>

                    <Box>
                        <Typography>State</Typography>

                        <RadioGroup row onChange={({ target: { value } }) => setParams({ ...params, state: value })} value={params.state}>
                            <FormControlLabel value='all' control={<Radio />} label='All' />
                            <FormControlLabel value='SCHEDULED' control={<Radio />} label='Scheduled' />
                            <FormControlLabel value='FINAL' control={<Radio />} label='Final' />
                            <FormControlLabel value='CANCELLED' control={<Radio />} label='Cancel' />
                            <FormControlLabel value='LIVE' control={<Radio />} label='Live' />
                        </RadioGroup>
                    </Box>
                </Paper>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant='contained' onClick={() => { onSearch(); setIndex(1) }}>Search</Button>
                </Box>
            </>

            {renderMenu()}
            <Paper sx={{ p: 2, my: 2, position: 'relative' }}>
                {updateLoading && <LinearProgress sx={{position: 'absolute', top: 0, left: 0, width: '100%'}} />}
                <Loading loading={loading}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>startAt</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data?.events?.map(evt => (
                                <TableRow row>
                                    <TableCell>{evt.name}</TableCell>
                                    <TableCell>
                                        <ChangeEventStart event={evt} sport={params.sport}/>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            disabled={locks.find(id => evt.id === id)}
                                            aria-label="more"
                                            id="long-button"
                                            aria-haspopup="true"
                                            onClick={(e) => onMenu(e, evt)}>
                                            <MoreVert />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Loading>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    count={data?.count ? Math.ceil(data?.count / params.take) : 0}
                    onChange={onPageChange} />
            </Box>
        </>
    )
} 