import React, { useEffect , useState} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  TextField, Grid, Paper, CircularProgress, Box, Typography, Divider} from '@mui/material';
import Moment from 'moment';
import { REQUEST_METHOD, REQUEST_STATUS, useLazyRequest } from '../hooks/request.hooks';


export default function UnMappedSearch({unMapped, type, sport, setLoading, setNeedRefetch}) {
    const { loading: setHelperLoading, response: setHelperResponse, request: setHelperRequest} = useLazyRequest(`/mapping/nameHelper`, REQUEST_METHOD.POST, true);
    const {loading: searchLoading, response: searchResponse, request: searchRequest} = useLazyRequest(`/search`, REQUEST_METHOD.GET, true);
    const [search, setSearch] = useState("");
    const [result, setResult] = useState([])

    useEffect(() => {
        if (unMapped)
            setSearch(unMapped.name)
    }, [unMapped])

    useEffect(() => {
        if (search && search.length >= 2) {
            searchRequest({type: type, search: search, sport: unMapped.sport}, {});
        }
    }, [search])

    useEffect(() => {
        setLoading(searchLoading || setHelperLoading)
    }, [searchLoading, setHelperLoading])

    useEffect(() => {
        setNeedRefetch(true)
    }, [setHelperResponse])

    useEffect(() => {
        setResult([])
        setSearch("")
    }, [type, sport])

    useEffect(() => {
        if (searchResponse && searchResponse.status === REQUEST_STATUS.SUCCESS) {
            const tempResult = []
            searchResponse.data.forEach(element => {
                console.log(element.name)
                tempResult.push({
                    name: element.fullName || element.name,
                    id: element.id,
                })
                console.log(tempResult)
            });
            setResult(tempResult)
        }
    }, [searchResponse])

    function handleUserText(event) {
        setSearch(event.target.value)
    }

    const createNewHelper = (row) => {
        setHelperRequest({}, {unmappedId: unMapped.id, foundId: row.id});
        setSearch("")
        setResult([])
    };

    return (
        <Box sx={{display:'flex', flexDirection: 'column'}}>
            <Typography sx={{ textAlign: 'center'}} variant="h6" component="h2">Description :</Typography>
            <Grid container spacing={2} justifyContent="center" sx={{py: 2}}>
                <Grid item xs={6}>
                    <Typography>id:</Typography>
                    <Typography>{unMapped.id}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>name:</Typography>
                    <Typography>{unMapped.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>createdAt:</Typography>
                    <Typography>{unMapped.createdAt === "" ? "" : Moment(unMapped.createdAt).format('MM-DD-YYYY')}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>type:</Typography>
                    <Typography>{unMapped.type}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>sport:</Typography>
                    <Typography>{unMapped.sport}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>source:</Typography>
                    <Typography>{unMapped.source}</Typography>
                </Grid>
            </Grid>
            <Divider/>

            <Typography variant="h9" component="h4" sx= {{pt: 2}}>search :</Typography>
            <TextField id="search_user"  variant="standard" value={search} onChange={handleUserText} />
            {
                setHelperLoading || searchLoading ?
                    <Box sx={{ display: 'flex' }} justifyContent={"center"}>
                        <CircularProgress />
                    </Box>
                    :
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {result.map((row) => (
                                    <TableRow key={row.id} onClick={() => createNewHelper(row)} hover>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </Box>
    );
};