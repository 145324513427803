import React from 'react'
import {
    Button,
    ButtonGroup
} from "@mui/material";

export default function SportSelect({ sport, setSport }) {
    return (
        <ButtonGroup sx={{ py: 2 }} aria-label="large button group">
            <Button variant={sport === 'tennis' ? 'contained' : 'outlined'} onClick={() => setSport('tennis')}>Tennis</Button>
            <Button variant={sport === 'basketball' ? 'contained' : 'outlined'} onClick={() => setSport('basketball')}>Basketball</Button>
            <Button variant={sport === 'soccer' ? 'contained' : 'outlined'} onClick={() => setSport('soccer')}>Soccer</Button>
        </ButtonGroup>
    )
}