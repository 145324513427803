import React, { useEffect, useState } from 'react';
import SportSelect from '../components/sportSelect';
import { BOBaseURL } from '../constant';
import useAxios from '../hooks/axios.hook';
// import LeagueSelect from '../components/leagueSelect'
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { Button, Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Breadcrumbs, Typography } from '@mui/material';

export default function TeamsPage({ }) {
    const [params, setParams] = useState({ sport: 'tennis', search: '', league: 'all', take: 25, index: 1 })
    const [search, setSearch] = useState('')

    const { loading: loadingTeams, data, request: getTeams } = useAxios({ lazy: true })

    const history = useHistory()

    useEffect(() => {
        getTeams({
            search: params.search,
            league: params.league === 'all' ? '' : params.league,
            take: params.take,
            skip: (params.index - 1) * params.take
        }, null, `${BOBaseURL}sport/${params.sport}/teams`)
    }, [params])

    const setSport = (value) => {
        setParams({ sport: value, search: '', league: 'all', take: 25, index: 1 })
    }

    // const setLeague = (value) => {
    //     setParams({ ...params, league: value })
    // }

    const onSearch = () => {
        setParams({ ...params, take: 25, index: 1, search })
    }

    const onKeyPress = (event) => {
        if (event && event.key === 'Enter') onSearch()
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress)

        return () => document.removeEventListener('keydown', onKeyPress)
    }, [search, params])

    return (
        <>
            <Breadcrumbs sx={{ py: 1 }}>
                <Typography>Sport</Typography>
                <Typography color="text.primary">Teams</Typography>
            </Breadcrumbs>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <SportSelect setSport={setSport} sport={params.sport} />

                {/* <Paper sx={{ p: 2, my: 1 }}>
                <LeagueSelect setLeague={setLeague} league={params.league} sport={params.sport} />
            </Paper> */}

                <Paper sx={{ p: 2, my: 1 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField fullWidth label='Name' value={search} onChange={({ target: { value } }) => setSearch(value)} />
                        <Button variant='contained' onClick={onSearch}>Search</Button>
                    </Box>
                </Paper>

                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data?.teams?.map((team, index) => (
                                <TableRow hover key={index} onClick={() => history.push(`teams/${params.sport}/${team.id}`)}>
                                    <TableCell>{team.fullName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>

                <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                    <Pagination count={data?.count ? Math.ceil(data.count / params.take) : 0} page={params.index} onChange={(_, index) => setParams({ ...params, index })} />
                </Box>
            </Box>
        </>
    )
}