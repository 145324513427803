import React, {useState} from 'react'
import {Box, Container, Typography, Paper, Button, FormControl, InputLabel, Select, MenuItem, TextField} from "@mui/material";
import moment from 'moment'
import { useEffect } from 'react';
import Loading from '../components/loading';
import useAxios from '../hooks/axios.hook';
import { BOBaseURL } from '../constant';
import CreateContest from '../components/contest/createContest';
import EditContest from '../components/contest/editContest';

function EditUsersCredits({setEditUser, uData}) {
    const [selected, setSelected] = useState({})
    const [newCredits, setNewCredits] = useState(undefined)

    const {request: dRequest, data: dData} = useAxios({url: `${BOBaseURL}contest/${selected?.id}/users`, method: 'post', lazy: true})

    useEffect(() => {
        if (selected)
            setNewCredits(selected.credits)
    }, [selected])

    const changeUserCredits = () => {
        dRequest(null, {credits: newCredits})
        setEditUser(false)
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{width: 500, marginTop: 4, display: 'flex', gap: 5, alignItems: 'center'}}>
                <FormControl sx={{width: 200}}>
                    <InputLabel id="demo-simple-select-label">User</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selected}
                        label="User"
                        onChange={(e) => setSelected(e.target.value)}
                    >
                    {uData.map(elem => 
                        {
                            return <MenuItem value={elem}>{elem.user.name}</MenuItem>
                        }
                    )}
                    </Select>
                </FormControl>
                <TextField sx={{width: 100}} label='Credits' id="outlined-basic" variant="outlined" value={newCredits ? newCredits : ''} onChange={(e) => setNewCredits(e.target.value)}/>
                <Button sx={{height: 40}} variant='contained' onClick={() => changeUserCredits()}>Save</Button>
            </Box>
        </Box>
    )

}

function Contest({contest, onSave}) {
    const [edit, setEdit] = useState(false)
    const [editUser, setEditUser] = useState(false)
    const {request: cRequest} = useAxios({url: `${BOBaseURL}contest/`, method: 'post'})
    const {request} = useAxios({url: `${BOBaseURL}contest/${contest.id}/refresh`, method: 'post'})
    const {request: uRequest, data: uData} = useAxios({url: `${BOBaseURL}contest/${contest.id}/users`, method: 'get', lazy: true})

    const onChange = (_contest) => {
        setEdit(false)
        cRequest(null, {contest: _contest})
        onSave(_contest)
    }
    
    const onEditUser = () => {
        uRequest()
        setEditUser(!editUser)
    }


    return (
        <Container sx={{marginTop: 5, marginBottom: 5}}>
            <Paper sx={{padding: 2}}>
            {!edit ? (
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant={'h5'} >{contest.name}</Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Typography sx={{marginTop: 2}} variant={''} >{contest.description}</Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 2}}>
                        <Typography variant={'h6'} >{contest.credits} initial credits</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{marginRight: 1, fontWeight: 'bold', fontSize: 16}} variant={''} >From: </Typography>
                        <Typography variant={''} >{moment(contest.startAt).format('dddd DD MMMM HH:mm').toString()}</Typography>
                    </Box>
                    <Box sx={{marginBottom: 2}}>
                        <Typography sx={{marginRight: 1, fontWeight: 'bold', fontSize: 16}} variant={''} >To: </Typography>
                        <Typography variant={''} >{moment(contest.endAt).format('dddd DD MMMM HH:mm').toString()}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{marginRight: 1, fontSize: 16, fontWeight: 'bold'}} variant={''} >Sport:</Typography>
                        <Typography variant={''} >{contest.sport}</Typography>
                    </Box>
                    <Box sx={{}}>
                        <Typography sx={{marginTop: 3, marginRight: 1, fontSize: 16, fontWeight: 'bold'}} variant={''} >League(s):</Typography>
                        {contest.leagueId.map(elem => <Typography variant=''>{elem}, </Typography>)}
                    </Box>
                    {contest.sport === 'tennis' && 
                        <Box>
                            <Typography sx={{marginTop: 3, fontSize: 18}} variant={''} >Tournament(s):</Typography>
                            {contest.tournamentId.map(elem => <Typography>{elem}, </Typography>)}
                        </Box>}  
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => onEditUser()}> Update user credits</Button>
                        <Box>
                            <Button sx={{backgroundColor: 'green', marginRight: 5}} variant='contained' onClick={() => request()}>Refresh</Button>
                            <Button variant='contained' onClick={() => setEdit(true)}>Update</Button>
                        </Box>
                    </Box>
                    {editUser && uData && <EditUsersCredits editUser={editUser} setEditUser={() => setEditUser(false)} uData={uData} />}
                </Box>
            ) : (
                <EditContest contest={contest} onChange={(elem) => onChange(elem)} />
            )}
            </Paper>
        </Container>
    )
}

export default function BetContestPage({}) {
    const {data} = useAxios({url: `${BOBaseURL}contest/`, method: 'get'})
    const [contests, setContests] = useState()

    useEffect(() => {
        if (data) setContests(data)
    }, [data])

    if (!contests) return <Loading />
    return (
        <Container>
            <CreateContest onNew={(elem) => setContests([...contests, elem])}/>
            <Typography sx={{marginTop: 10, marginLeft: 5}} variant='h4'>Current Contests</Typography>
            {contests.sort(function(a, b) { return new Date(a.endAt) - new Date(b.endAt)} ).map(elem => <Contest contest={elem} onSave={(elem) => setContests([...contests.filter(el => elem.id !== el.id), elem])}/>)}
        </Container>
    )
}