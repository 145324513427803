import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import {useEffect, useState} from "react";
import Loading from "../loading";
import {LoadingButton} from "@mui/lab";
import moment from "moment";


function Modal({open, period, onClose}) {
    const {request, loading, data} = useAxios({url: `${BOBaseURL}user/recurring`, method: 'get', lazy: true})
    const {request: sRequest, loading: sLoading, data: sData} = useAxios({url: `${BOBaseURL}user/access/free`, method: 'post', lazy: true})

    const [freePeriod, setFreePeriod] = useState(7)

    const onSend = () => {
        if (data) sRequest({}, {expiredDate: moment().add(freePeriod, 'd').format('YYYY-MM-DD'), list: data})
        else onClose()
    }

    useEffect(() => {
        if (sData) onClose()
    }, [sData])

    useEffect(() => {
        if (open) request({period})
    }, [open])
    
    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            onClose={onClose}
            open={open}>
            <DialogTitle>Free access</DialogTitle>
            <Loading loading={loading || !data}>
                {data && <DialogContent dividers >
                    <Typography>Number of recurring user during the last {period} days: <b>{data.length}</b></Typography>
                </DialogContent>}
            </Loading>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton loading={sLoading} variant={'contained'} onClick={onSend}>Give {freePeriod} days access</LoadingButton>
            </DialogActions>
            
        </Dialog>
    )
}

export default function SendFreeAccess({}) {
    const [open, setOpen] = useState(false)

    const onRun = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    return (
        <Card sx={{m: 1}}>
            <Modal open={open} onClose={onClose} period={4}/>
            <CardContent>
                <Typography>Send Free access to recurring users</Typography>
                <div>
                    <Button onClick={onRun} variant={'contained'}>Run</Button>
                </div>
            </CardContent>
        </Card>
    )
}