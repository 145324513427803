import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useHistory } from "react-router-dom";
import { Collapse, ListItemButton, ListItemIcon, ListSubheader } from "@mui/material";
import { AccountTree, AttachMoney, Dashboard, Equalizer, Groups, Lock, Person, SportsTennis, Event, GroupWork } from "@mui/icons-material";
import { useState } from "react";


const styleBar = {
    //width: '100%',
    minWidth: 200,
};

function NavigationMenuList() {
    let history = useHistory();

    const [open, setOpen] = useState()

    const handelOpen = (value) => {
        if (open === value) setOpen(undefined)
        else setOpen(value)
    }

    return (
        <List sx={styleBar} component="nav" aria-label="mailbox folders">
            <ListItem sx={{}} button onClick={() => history.push("/board")}>
                <ListItemIcon>
                    <Dashboard sx={{ color: 'grey.600' }} />
                </ListItemIcon>
                <ListItemText primary="dashboard" />
            </ListItem>
            <ListItem sx={{}} button onClick={() => history.push("/analytics")}>
                <ListItemIcon>
                    <Equalizer sx={{ color: 'grey.600' }} />
                </ListItemIcon>
                <ListItemText primary="Analytics" />
            </ListItem>
            <ListItem sx={{}} button onClick={() => history.push("/user")}>
                <ListItemIcon>
                    <Person sx={{ color: 'grey.600' }} />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItem>
            <Divider />
            <ListItem sx={{}} button onClick={() => history.push("/mapping")}>
                <ListItemIcon>
                    <AccountTree sx={{ color: 'grey.600' }} />
                </ListItemIcon>
                <ListItemText primary="Mapping Name" />
            </ListItem>
            <ListItem sx={{}} button onClick={() => history.push("/restricted-event")}>
                <ListItemIcon>
                    <Lock sx={{ color: 'grey.600' }} />
                </ListItemIcon>
                <ListItemText primary="Restricted" />
            </ListItem>
            <ListItem sx={{}} button onClick={() => history.push("/bet-contest")}>
                <ListItemIcon>
                    <Person sx={{ color: 'grey.600' }} />
                </ListItemIcon>
                <ListItemText primary="Bet Contest" />
            </ListItem>
            <ListItem sx={{}} button onClick={() => history.push("/tips")}>
                <ListItemIcon>
                    <Equalizer sx={{ color: 'grey.600' }} />
                </ListItemIcon>
                <ListItemText primary="Tips" />
            </ListItem>

            <List subheader={<ListSubheader sx={{ backgroundColor: 'unset', color: 'background.contrast.contrastText' }}>Sports</ListSubheader>}>
                <ListItemButton onClick={() => handelOpen('tennis')}>
                    <ListItemIcon>
                        <SportsTennis sx={{ color: 'grey.600' }} />
                    </ListItemIcon>
                    <ListItemText primary="Tennis" />
                </ListItemButton>
                <Collapse in={open === 'tennis'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary="Tournament" onClick={() => history.push("/tennis/tournament")} />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} disabled>
                            <ListItemText primary="Event" />
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItem sx={{}} button onClick={() => history.push("/teams")}>
                    <ListItemIcon>
                        <Groups sx={{ color: 'grey.600' }} />
                    </ListItemIcon>
                    <ListItemText primary="Teams" />
                </ListItem>

                <ListItem sx={{}} button onClick={() => history.push("/players")}>
                    <ListItemIcon>
                        <Person sx={{ color: 'grey.600' }} />
                    </ListItemIcon>
                    <ListItemText primary="Players" />
                </ListItem>

                <ListItem sx={{}} button onClick={() => history.push("/events")}>
                    <ListItemIcon>
                        <Event sx={{ color: 'grey.600' }} />
                    </ListItemIcon>
                    <ListItemText primary="Events" />
                </ListItem>

                <ListItem sx={{}} button onClick={() => history.push("/leagues")}>
                    <ListItemIcon>
                        <GroupWork sx={{ color: 'grey.600' }} />
                    </ListItemIcon>
                    <ListItemText primary="Leagues" />
                </ListItem>
            </List>
        </List>
    );
}


export default NavigationMenuList;