import React, { useEffect , useState} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, TablePagination,} from '@mui/material';
import {REQUEST_METHOD, REQUEST_STATUS, useRequest} from "../hooks/request.hooks";

export default function MappingTable ({sport, type, onLoading, setSelected, needRefetch, setNeedRefetch}) {
    const {loading: unmapLoading, response: unmapResponse, refetch: unmapRefetch} = useRequest(`/mapping/unmapped`, REQUEST_METHOD.GET, {}, {}, true);
    const defaultUnmapped = { id: "", name: "", createdAt: "", type: "", sport: "", source: ""}
    const [unmapped, setUnmapped] = useState([]);
    const [filtredUnmapped, setFiltredUnmapped] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if (unmapResponse && unmapResponse.status === REQUEST_STATUS.SUCCESS) {
            const tempUnmap = []
            unmapResponse.data.forEach(element => {
                tempUnmap.push({
                    name: element.name,
                    id: element.id,
                    source: element.source,
                    sport: element.sport,
                    type: element.type
                })
            });
            setUnmapped(tempUnmap)
            setFiltredUnmapped(tempUnmap.filter(element => element.type === type && (element.sport === sport || sport === '')))
        }
    }, [unmapResponse])

    useEffect(() => {
        setFiltredUnmapped(unmapped.filter(element => element.type === type && (element.sport === sport || sport === '')))
        setSelected(defaultUnmapped)
        setPage(0);
    }, [type, sport])

    useEffect(() => {
        onLoading(unmapLoading);
    }, [unmapLoading])

    useEffect(() => {
        if (needRefetch) {
            unmapRefetch()
            setSelected(defaultUnmapped)
            setNeedRefetch(false)
        }
    }, [needRefetch])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
        <div>
            {
                unmapLoading ?
                    <Box sx={{ display: 'flex' }} justifyContent={"center"}>
                        <CircularProgress />
                    </Box>
                    :
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>name</TableCell>
                                        <TableCell>sport</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filtredUnmapped
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow key={row.id} onClick={() => setSelected(row)} hover>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.sport}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            component="div"
                            SelectProps={{
                                inputProps: {
                                  'aria-label': 'rows per page',
                                },
                                native: true,
                              }}
                            count={filtredUnmapped.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
            }
        </div>
    );
};