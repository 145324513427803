import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { BOBaseURL } from '../constant'
import Loading from '../components/loading'
import {
    Paper,
    Box,
    TextField,
    Breadcrumbs,
    Typography,
    Button,
    Alert,
    Snackbar
} from '@mui/material'
import useAxios from '../hooks/axios.hook'

export default function PlayerPage({ }) {
    const { sport, id } = useParams()
    const { loading, data } = useAxios({ url: `${BOBaseURL}sport/${sport}/player/${id}` })
    const { loading: uLoading, data: updated, request: update, error: updateError } = useAxios({ method: 'post', lazy: true })

    const [player, setPlayer] = useState(null)
    const [snackOpen, setSnackOpen] = useState(false)

    useEffect(() => {
        if (data) setPlayer(data)
    }, [data])

    useEffect(() => {
        if (updated || updateError) setSnackOpen(true)
    }, [updated, updateError])

    const onUpdate = () => {
        update(null, {
            fullName: player.fullName
        }, `${BOBaseURL}sport/${sport}/player/${id}`)
    }

    return (
        <>
            <Snackbar open={snackOpen} onClose={() => setSnackOpen(false)} autoHideDuration={6000}>
                {updateError ?
                    <Alert onClose={() => setSnackOpen(false)} security={'error'}>
                        Error during player update.
                    </Alert>
                    :
                    <Alert onClose={() => setSnackOpen(false)} severity={'success'}>
                        Player updated with success.
                    </Alert>
                }
            </Snackbar>

            <Box>
                <Breadcrumbs sx={{ py: 1 }}>
                    <Typography>Sport</Typography>
                    <Link
                        underline="hover"
                        color="inherit"
                        to="/players"
                    >
                        Players
                    </Link>
                    <Typography>{sport}</Typography>
                    <Typography  color="text.primary">{id}</Typography>
                </Breadcrumbs>

                <Loading loading={loading || !player}>
                    <Paper sx={{ p: 2 }}>
                        <TextField label='Full name' fullWidth value={player?.fullName} onChange={(({ target: { value } }) => setPlayer({ ...player, fullName: value }))} />
                    </Paper>

                    <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onUpdate} variant='contained'>Update</Button>
                    </Box>
                </Loading>
            </Box>
        </>
    )
}