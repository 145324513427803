import React, { useState } from 'react'
import { Box, Typography, Paper, Button, TextField } from "@mui/material";
import { useEffect } from 'react';
import OfferModal from './offerModal';
import OfferCard from './offerCard';
import { Close } from '@mui/icons-material';

function formatOffer(off) {
  return {
    id: off.id,
    label: off.label,
    sport: off.sport,
    market: {
      id: off.market.id,
      type: off.market.type
    },
    event: {
      id: off.event.id,
      name: off.event.name,
      startAt: off.event.startAt,
      participants: off.event.participants.map((elem) => ({ image: elem.image ? elem.image : elem.team?.nationality, name: elem.name, shortName: elem.shortName }))
    },
    marketId: off.marketId,
    odd: off.odd,
    type: off.type,
    bookmakerId: off.bookmakerId,
    value: off.value
  }
}

export default function TipsPaper({ onAddTips, onChangeTips, isEdit = false, tips = null, setEdit }) {
  const [open, setOpen] = useState(false)
  const [tip, setTip] = useState({ title: '', description: '', sign: '', link: '', offers: [] })

  useEffect(() => {
    if (tips) setTip(tips)
  }, [tips])

  const onAddOffer = (off) => {
    setTip({ ...tip, offers: [...tip.offers, formatOffer(off)] })
  }

  const onDeleteOffer = (id) => {
    setTip({ ...tip, offers: tip.offers.filter(elem => elem.id !== id) })
  }

  const onCreateTips = (tip) => {
    console.log(tip)
    onAddTips(tip)
    setTip({ title: '', description: '', sign: '', offers: [] })
  }

  const onEditTips = (tip) => {
    onChangeTips(tip)
    setEdit(false)
  }

  return (
    <div>
      <div className='flex justify-between items-center'>
      <Typography variant={'h4'} >Tips</Typography>
        <div className='cursor-pointer' onClick={() => setEdit(false)}>
          <Close />
        </div>
      </div>
      <Paper sx={{ padding: 5 }}>
        <OfferModal open={open} setOpen={setOpen} onAddOffer={(off) => onAddOffer(off)} />
        <Box>
          <Typography variant={''} >Title</Typography>
          <TextField sx={{ marginBottom: 2 }} size='small' fullWidth value={tip.title} onChange={({ target: { value } }) => setTip({ ...tip, title: value })} />
          <Typography variant={''} >Description</Typography>
          <TextField sx={{ marginBottom: 2 }} multiline={true} size='medium' fullWidth value={tip.description} onChange={({ target: { value } }) => setTip({ ...tip, description: value })} />
        </Box>
        <Box>
          <Typography variant={''}>Signature</Typography>
          <TextField sx={{ marginBottom: 2 }} size='small' fullWidth value={tip.sign} onChange={({ target: { value } }) => setTip({ ...tip, sign: value })} />
        </Box>
        <Box>
          <Typography variant={''}>Lien redirection</Typography>
          <TextField sx={{ marginBottom: 2 }} size='small' fullWidth value={tip.link} onChange={({ target: { value } }) => setTip({ ...tip, link: value })} />
        </Box>
        <Box>
          <Typography variant={'h5'}>Offers</Typography>
          {tip.offers?.map((off, index) => <OfferCard key={index} off={off} adding={false} onDeleteOffer={(id) => onDeleteOffer(id)} />)}
          <Button sx={{ marginTop: 2 }} variant='contained' onClick={() => setOpen(true)}>Ajouter une offer à ce tips</Button>
        </Box>
      </Paper>

      <Box className='mt-5' sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!tips ? <Button variant='contained' sx={{ backgroundColor: 'green' }} onClick={() => onCreateTips(tip)}>Creer ce Tips</Button> : <Button variant='contained' sx={{ backgroundColor: 'green' }} onClick={() => onEditTips(tip)}>Valider</Button>}
      </Box>
    </div>
  )
}