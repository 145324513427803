import React, { useEffect } from "react";
import Loading from "./loading"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { BOBaseURL } from '../constant';
import useAxios from '../hooks/axios.hook';

export default function LeagueSelect({ sport, setLeague, league }) {
    const { loading: loadingLeagues, data: leagues, request: getLeagues } = useAxios({ lazy: true })

    useEffect(() => {
        if (sport) getLeagues(null, null, `${BOBaseURL}sport/${sport}/leagues`)
    }, [sport])

    return (
        <Loading loading={loadingLeagues}>
            <FormControl fullWidth >
                <Select size='small' aria-label="large button group" value={league} onChange={({ target: { value } }) => setLeague(value)}>
                    <MenuItem value='all'>All</MenuItem>
                    {leagues?.map(data => <MenuItem key={data.name} value={data.name}>{data.displayName}</MenuItem>)}
                </Select>
            </FormControl>
        </Loading>
    )
}