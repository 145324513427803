import RefetchContext from "./context/refetch.context";
import AppLayout from "./appLayout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login.pages";
import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import { useTokenContext } from "./context/token.context";
import { getAuth } from "@firebase/auth";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ToggleColorMode from "./components/styles/style";
import Mapping from "./pages/mapping";
import { ViewportProvider } from "./context/viewport.context";
import AnalyticsPage from "./pages/analytics.page";
import DashboardPage from "./pages/dashboard.page";
import UserPage from "./pages/user.page";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { TournamentsPage } from "./pages/tennis/tournaments.page";
import TournamentPage from "./pages/tennis/tournament.page";
import RestrictedEventPage from "./pages/restrictedEvent.page";
import TeamsPage from "./pages/teams.page";
import TeamPage from "./pages/team.page";
import PlayerPage from "./pages/player.page";
import PlayersPage from "./pages/players.page";
import EventPage from "./pages/events.page";
import LeaguesPage from "./pages/leagues.page";
import LeaguePage from "./pages/league.page";
import BetContestPage from "./pages/betContest.page";
import TipsPage from "./pages/tipsPage";
import DailyTipsCreation from "./pages/tipsCreate.page";
import "./index.css";

const routes = [
  {
    path: "/board",
    main: () => <DashboardPage />,
  },
  {
    path: "/user",
    main: () => <UserPage />,
  },
  {
    path: "/mapping",
    main: () => <Mapping />,
  },
  {
    path: "/analytics",
    main: () => <AnalyticsPage />,
  },
  {
    path: "/tennis/tournament",
    exact: true,
    main: () => <TournamentsPage />,
  },
  {
    path: "/tennis/tournament/:id",
    main: () => <TournamentPage />,
  },
  {
    path: "/restricted-event",
    main: () => <RestrictedEventPage />,
  },
  {
    path: "/bet-contest",
    main: () => <BetContestPage />,
  },
  {
    path: "/tips",
    exact: true,
    main: () => <TipsPage />,
  },
  {
    path: "/tips/:id",
    exact: true,
    main: () => <DailyTipsCreation />,
  },
  {
    path: "/tipsCreate",
    main: () => <DailyTipsCreation />,
  },
  {
    path: "/teams",
    exact: true,
    main: () => <TeamsPage />,
  },
  {
    path: "/teams/:sport/:id",
    exact: true,
    main: () => <TeamPage />,
  },
  {
    path: "/players",
    exact: true,
    main: () => <PlayersPage />,
  },
  {
    path: "/players/:sport/:id",
    exact: true,
    main: () => <PlayerPage />,
  },
  {
    path: "/events",
    exact: true,
    main: () => <EventPage />,
  },
  {
    path: "/leagues",
    exact: true,
    main: () => <LeaguesPage />,
  },
  {
    path: "/leagues/:sport/:id",
    exact: true,
    main: () => <LeaguePage />,
  },
];

function App() {
  const { token, setToken } = useTokenContext();
  const [userConnected, setUserConnected] = useState(false);
  const firebaseConfig = {
    apiKey: "AIzaSyDfI-_Q6LQO6W37J9C7TeRp-M-xONznH24",
    authDomain: "valueshunter-2710c.firebaseapp.com",
    projectId: "valueshunter-2710c",
    storageBucket: "valueshunter-2710c.appspot.com",
    messagingSenderId: "115072039721",
    appId: "1:115072039721:web:7920d39c98fc0c40129538",
    measurementId: "G-3QF1WMTM9E",
  };
  initializeApp(firebaseConfig);
  // const auth = getAuth();

  const theme = createTheme({
    palette: {
      background: {
        default: "#efefef",
        contrast: {
          main: "#171717",
          contrastText: "#efefef",
        },
      },
    },
  });

  // useEffect(() => {
  //   auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       //user signed in
  //       setToken(await auth.currentUser.getIdToken());
  //       setUserConnected(true);
  //     } else {
  //       //user signed out
  //       setUserConnected(false);
  //       setToken("");
  //     }
  //   });
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <RefetchContext>
        <ViewportProvider>
          <Router>
            {/* {!token || token === "" ?
                  <Login />
                  : */}
            <div>
              {/* {!userConnected ? (
                <Box sx={{ display: "flex" }} justifyContent={"center"}>
                  <CircularProgress />
                </Box>
              ) : ( */}
              <AppLayout>
                <Switch>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.main}
                    />
                  ))}
                </Switch>
              </AppLayout>
              {/* )} */}
            </div>
            {/* } */}
          </Router>
        </ViewportProvider>
      </RefetchContext>
    </ThemeProvider>
  );
}

export default App;
