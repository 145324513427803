import React, { Fragment, useState } from 'react'
import { Box, Container, Typography, Paper, Button, FormControl, InputLabel, Select, MenuItem, TextField, Modal, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useEffect } from 'react';
import Loading from '../components/loading';
import useAxios from '../hooks/axios.hook';
import { BOBaseURL } from '../constant';
import { useHistory } from "react-router-dom";
import DailyTipsCard from '../components/tips/dailyTipsCard';
import moment from 'moment'

export default function TipsPage({ }) {
  const [allDailyTips, setAllDailyTips] = useState(null)
  const { request: dRequest } = useAxios({ method: 'delete', lazy: true })
  const { loading: gLoading, data: gData } = useAxios({ url: `${BOBaseURL}tips` })
  const history = useHistory()

  useEffect(() => {
    if (gData) setAllDailyTips(gData)
  }, [gData])

  if (gLoading || !gData) return <Loading />
  return (
    <Container>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ marginY: 3 }} variant={'h4'}>Your Daily Tips</Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button variant='contained' sx={{ height: 40 }} onClick={() => history.push(`/tipsCreate`)}>Create new</Button>
          </Box>
        </Box>

        <Paper>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Notified</TableCell>
                <TableCell>Expired</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {allDailyTips && allDailyTips?.map((elem, index) => (
                <TableRow key={index} hover={true} onClick={() => history.push(`tips/${elem.id}`, { dailyTips: elem, edit: true })}>
                  <TableCell>{moment(elem.createdAt).format('DD MMM')}</TableCell>
                  <TableCell>{elem.notified ? 'true' : 'false'}</TableCell>
                  <TableCell>{elem.expired ? 'true' : 'false'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Container>
  )
}