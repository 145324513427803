import React, {useState} from 'react'
import Users from "../components/user/users";
import {Container} from "@mui/material";
import SendFreeAccess from "../components/action/sendFreeAccess";

export default function UserPage({}) {
    return (
        <Container>
            <SendFreeAccess />
            <Users />
        </Container>
    )
}