import React, {useEffect, useState} from 'react'
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import {
    Breadcrumbs, Button,
    ButtonGroup,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import Loading from "../../components/loading";

export function TournamentsPage({}) {
    const [league, setLeague] = useState('atptour')
    const {data, loading, error, request} = useAxios({url: `${BOBaseURL}tennis/tournament/league/${league}`, lazy: true, method: 'get'})

    useEffect(() => {
        request(null, null, `${BOBaseURL}tennis/tournament/league/${league}`)
    }, [league])

    const history = useHistory()

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb" sx={{my: 1}}>
                <Typography>Tennis</Typography>
                <Typography color="text.primary">Tournaments</Typography>
            </Breadcrumbs>

            <ButtonGroup sx={{my: 1}}>
                <Button variant={league === 'atptour' ? 'contained' : 'outlined'} onClick={() => setLeague('atptour')}>ATP</Button>
                <Button variant={league === 'wta' ? 'contained' : 'outlined'} onClick={() => setLeague('wta')}>WTA</Button>
            </ButtonGroup>

            <Paper>
                <Loading loading={loading}>
                    <Table>
                        <TableHead>
                            <TableCell>Name</TableCell>
                            <TableCell>Level</TableCell>
                        </TableHead>

                        <TableBody>
                            {data?.map(elem =>
                                <TableRow hover key={elem.id} onClick={() => history.push(`/tennis/tournament/${elem.id}`)}>
                                    <TableCell>{elem.displayName}</TableCell>
                                    <TableCell>{elem.extra?.level === undefined ? 'not set' : elem.extra?.level}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Loading>
            </Paper>
        </Box>
    )
}