import { TextField, Typography, Snackbar, Alert, Box, Paper, Button, Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { BOBaseURL } from '../constant'
import useAxios from '../hooks/axios.hook'
import Loading from '../components/loading'
import CountryCodeSelect from '../components/countryCodeSelect'

export default function LeaguePage({ }) {
    const { sport, id } = useParams()
    const { data, loading } = useAxios({ url: `${BOBaseURL}sport/${sport}/league/${id}` })
    const { data: updated, request: update, loading: updateLoading, error: updateError } = useAxios({ lazy: true, method: 'post', url: `${BOBaseURL}sport/${sport}/league/${id}` })

    const [league, setLeague] = useState()
    const [snackOpen, setSnackOpen] = useState(false)

    useEffect(() => {
        if (data) setLeague(data)
    }, [data])

    useEffect(() => {
        if (updated) setSnackOpen(true)
    }, [updated])

    const onUpdate = () => {
        update(null, {
            displayName: league.displayName,
            shortName: league.shortName,
            region: league.region
        })
    }

    return (
        <>
            <Snackbar open={snackOpen} onClose={() => setSnackOpen(false)} autoHideDuration={6000}>
                {updateError ?
                    <Alert onClose={() => setSnackOpen(false)} security={'error'}>
                        Error during league update.
                    </Alert>
                    :
                    <Alert onClose={() => setSnackOpen(false)} severity={'success'}>
                        League updated with success.
                    </Alert>
                }
            </Snackbar>

            <Breadcrumbs sx={{ py: 1 }}>
                <Typography>Sport</Typography>
                <Link
                    underline="hover"
                    color="inherit"
                    to="/leagues"
                >
                    League
                </Link>
                <Typography>{sport}</Typography>
                <Typography color="text.primary">{id}</Typography>
            </Breadcrumbs>

            <Paper sx={{ p: 2 }}>
                <Loading loading={loading || !league}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <TextField sx={{ flex: 1 }} label='Display name' size='small' value={league?.displayName} onChange={({ target: { value } }) => setLeague({ ...league, displayName: value })} />
                            <TextField sx={{ flex: 1 }} label='Short name' size='small' value={league?.shortName} onChange={({ target: { value } }) => setLeague({ ...league, shortName: value })} />
                        </Box>
                        <CountryCodeSelect value={league?.region} onChange={(region) => setLeague({ ...league, region })} />
                    </Box>
                </Loading>
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
                <Button variant='contained' onClick={onUpdate}>Update</Button>
            </Box>
        </>
    )
}