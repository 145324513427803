import React, { useState } from 'react'
import { Box, Typography, Paper, Button, TextField, TableHead, TableRow, TableCell, TableBody, Table } from "@mui/material";
import { useEffect } from 'react';
import TipsPaper from '../components/tips/tipsPaper';
import useAxios from '../hooks/axios.hook';
import { BOBaseURL } from '../constant';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';

function SendNotification({dailyTips}) {
  const { data, request, lodaing } = useAxios({ method: 'put', lazy: true, url: `${BOBaseURL}tips/${dailyTips.id}/notification` })

  return (
    <Button variant='contained' disabled={lodaing} onClick={() => request()}>
      Send notification
    </Button>
  )
}

function Expire({dailyTips}) {
  const { data, request, lodaing } = useAxios({ method: 'put', lazy: true, url: `${BOBaseURL}tips/${dailyTips.id}/expire` })
  const [expired, setExpired] = useState(dailyTips.expired)

  useEffect(() => {
    if (data) setExpired(true)
  }, [data] )

  return (
    <Button variant='contained' disabled={expired || lodaing} onClick={() => request()}>
      Expire
    </Button>
  )
}

export default function DailyTipsCreation({ editedDailyTips = null }) {
  const [dailyTips, setDailyTips] = useState({ title: 'Highlights du jour dispo ! 🚀', description: 'Découvre la sélection des analyses intéressantes du jour', Tips: [], id: undefined, notified: false })
  const { data, request } = useAxios({ method: 'put', lazy: true })
  const history = useHistory()
  const { state } = useLocation()
  const [isEdit, setEdit] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const { request: uRequest } = useAxios({ method: 'post', lazy: true })

  useEffect(() => {
    if (data) history.push(`/tips`)
  }, [data])

  useEffect(() => {
    if (state?.dailyTips) setDailyTips(state.dailyTips)
    // if (state?.edit) setEdit(state.edit)
  }, [state])

  useEffect(() => {
    if (editedDailyTips) setDailyTips(editedDailyTips)
  }, [editedDailyTips])

  const onDeleteTips = (index) => {
    setDailyTips({ ...dailyTips, Tips: [...dailyTips.Tips.filter(elem => elem.index !== index)] })
  }

  const onAddTips = (tip) => {
    setDailyTips({ ...dailyTips, Tips: [...dailyTips.Tips, { ...tip, index: dailyTips.Tips.length }] })
    setEdit(false)
    setIsNew(false)
  }

  const onChangeTips = (tip) => {
    setDailyTips({
      ...dailyTips, Tips: dailyTips.Tips.map((elem) => {
        if (elem.index === tip.index) return tip
        return elem
      })
    })
    setEdit(false)
    setIsNew(false)
  }

  const onCreate = () => {
    request({}, { tips: dailyTips }, `${BOBaseURL}tips`)
    setDailyTips({ title: '', description: '', Tips: [] })
  }

  const onUpdate = () => {
    uRequest({}, { tips: dailyTips }, `${BOBaseURL}tips`)
    history.push(`/tips`)
  }

  const onEdit = (elem) => {
    setEdit(elem)
    setIsNew(false)
  }

  const onNew = () => {
    setIsNew(true)
    setEdit(undefined)
  }

  return (
    <div className='grid grid-cols-2 gap-5 h-full'>
      <div className={`${isNew || isEdit ? '' : 'col-span-2'} h-full overflow-y-auto`}>
        {!dailyTips.id ? <Typography variant={'h4'}>Create Daily Tips</Typography> : <Typography variant={'h4'}>Edit Daily Tips</Typography>}
        <Paper sx={{ padding: 5, marginBottom: 5 }} >
          <Box>
            <Typography variant={''} >Notification Title</Typography>
            <TextField sx={{ marginBottom: 2 }} size='small' fullWidth value={dailyTips.title} onChange={({ target: { value } }) => setDailyTips({ ...dailyTips, title: value })} />
            <Typography variant={''} >Notification Description</Typography>
            <TextField sx={{ marginBottom: 2 }} size='medium' fullWidth value={dailyTips.description} onChange={({ target: { value } }) => setDailyTips({ ...dailyTips, description: value })} />
          </Box>
        </Paper>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
          <Typography variant={'h4'}>Create Daily Tips</Typography>
          <Button onClick={() => onNew()} variant='contained'>Add Tips</Button>
        </div>
        <Paper className='space-y-3' sx={{ marginBottom: 5 }} >
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Offers</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dailyTips.Tips.map((elem, index) => (
                <TableRow hover={true} key={index}>
                  <TableCell>{elem.title}</TableCell>
                  <TableCell className='text-ellipsis overflow-hidden'>{elem.description}</TableCell>
                  <TableCell>{elem.offers?.length}</TableCell>
                  <TableCell>
                    <div className='flex space-x-2'>
                      <div className='cursor-pointer p-2 rounded-xl hover:bg-neutral-200' onClick={() => onEdit(elem)}>
                        <Edit />
                      </div>
                      <div className='cursor-pointer p-2 rounded-xl hover:bg-neutral-200' onClick={() => onDeleteTips(elem.index)}>
                        <Delete />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {dailyTips.id &&
          <div>
            <Typography variant={'h4'}>Actions</Typography>
            <Paper className='p-3 flex gap-2'>
              <SendNotification dailyTips={dailyTips} />
              <Expire dailyTips={dailyTips}/>
            </Paper>
          </div>
        }

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginY: 2 }}>
          {!dailyTips.id ? <Button variant='contained' sx={{ backgroundColor: 'green' }} onClick={() => onCreate()}>Creer ce DailyTips</Button> : <Button variant='contained' sx={{ backgroundColor: 'green' }} onClick={() => onUpdate()}>Valider</Button>}
        </Box>
      </div>

      {isNew &&
        <div className='h-full overflow-y-auto'>
          <TipsPaper setEdit={setIsNew} onAddTips={(tip) => onAddTips(tip)} />
        </div>
      }

      {isEdit &&
        <div className='h-full overflow-y-auto'>
          <TipsPaper setEdit={setEdit} tips={isEdit} onChangeTips={(tip) => onChangeTips(tip)} />
        </div>
      }
    </div>
  )
}