import React, {useState} from 'react'
import {Box, Typography,  TextField, Button} from "@mui/material";
import MomentUtils from '@date-io/moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


export default function EditContest({contest, onChange}) {
    const [_contest, setContest] = useState(contest)

    return (
        <Box>
            <Typography variant={''} >Name</Typography>
            <TextField sx={{marginBottom: 2}} size='small' fullWidth value={_contest.name} onChange={({ target: { value } }) => setContest({ ..._contest, name: value })} />
            <Typography variant={''} >Description</Typography> 
            <TextField sx={{marginBottom: 2}} size='medium' fullWidth value={_contest.description} onChange={({ target: { value } }) => setContest({ ..._contest, description: value })} />
            <Typography  variant={''} >Start At</Typography>
            <Box sx={{width: '100%', display: 'flex',  paddingY: 1, gap: 2, alignItems: 'center'}}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <DateTimePicker label="Start changer" value={_contest.startAt} ampm={false} onChange={(value) => setContest({..._contest, startAt: value})} inputFormat={"DD/MM/YY-HH:mm"} renderInput={(params) => <TextField {...params} />} />
                </LocalizationProvider>
            </Box>
            <Typography sx={{marginTop: 3}} variant={''} >End At</Typography>
            <Box sx={{width: '100%', display: 'flex',  paddingY: 1, gap: 2, alignItems: 'center'}}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <DateTimePicker label="Start changer" value={_contest.endAt} ampm={false} onChange={(value) => setContest({..._contest, endAt: value})} inputFormat={"DD/MM/YY-HH:mm"} renderInput={(params) => <TextField {...params} />} />
                </LocalizationProvider>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='contained' onClick={() => onChange(_contest)}>Save</Button>
            </Box>
        </Box>
    )
}