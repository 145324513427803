import React, {useState} from 'react'
import {Box, Container, Typography, Paper, TextField, Select, MenuItem, FormControl, InputLabel, Button} from "@mui/material";
import MomentUtils from '@date-io/moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment'
import { useEffect } from 'react';
import Loading from '../loading';
import useAxios from '../../hooks/axios.hook';
import { BOBaseURL } from '../../constant';

export default function CreateContest({onNew}) {
    const [contest, setContest] = useState({name: '', description: '', credits: 5, startAt: moment(), endAt: moment().add('d', 1), sport: 'tennis', tournamentId: [], tournamentNames: [], leagueId: [], leagueNames: []})
    const [tournaments, setTournaments] = useState([])
    const {data: cData, loading, request: cRequest} = useAxios({url: `${BOBaseURL}contest/`, method: 'put'})
    const {data, request} = useAxios({lazy: true, method: 'get'})
    const { data: leagues, request: pRequest } = useAxios({ lazy: true })

    useEffect(() => {
        if (contest.sport) {
            pRequest(null, null, `${BOBaseURL}sport/${contest.sport}/leagues`)
            setTournaments([])
            setContest({...contest, leagueId: [], leagueNames: [], tournamentId: [], tournamentNames: []})
        }
    }, [contest.sport])
    
    useEffect(() => {
        if (data) setTournaments([...tournaments.concat(data)])
    }, [data])

    const onAddLeague = (league) => {
        const newLeague = league.filter(elem => !contest.leagueId.includes(elem))
        if (!newLeague.length) {
            const deleted = contest.leagueId.filter(elem => !league.includes(elem))
            const tmp = leagues.find(elem => elem.name === deleted[0])
            const name = contest.leagueNames.filter(elem => elem !== tmp.displayName)
            setContest({...contest, leagueId: league, leagueNames: name})
        }
        else {
            request(null, null, `${BOBaseURL}tennis/tournament/league/${newLeague}`)
            const name = leagues.find(elem => elem.name === newLeague[0])
            console.log(name.displayName)
            setContest({...contest, leagueId: league, leagueNames: [...contest.leagueNames, name.displayName]})
        }
    }

    const onAddTournament = (tournament) => {
        const newTournament = tournament.filter(elem => !contest.tournamentId.includes(elem))
        if (!newTournament.length) {
            const deleted = contest.tournamentId.filter(elem => !tournament.includes(elem))
            const tmp = tournaments.find(elem => elem.id === deleted[0])
            const name = contest.tournamentNames.filter(elem => elem !== tmp.name)
            setContest({...contest, tournamentId: tournament, tournamentNames: name})
        }
        else {
            const name = tournaments.find(elem => elem.id === newTournament[0])
            setContest({...contest, tournamentId: tournament, tournamentNames: [...contest.tournamentNames, name.name]})
        }
    }

    const onCreate = () => {
        cRequest(null, {contest: contest})
        setContest({name: '', description: '', credits: 5, startAt: moment(), endAt: moment().add('d', 1), sport: 'tennis', tournamentId: [], tournamentNames: [], leagueId: [], leagueNames: []})
        onNew(contest)
    }

    if (!leagues) return <Loading />
    return (
        <Container>
            <Paper sx={{padding: 5}} >
                <Box sx={{marginBottom: 5}}>
                    <Typography variant={'h5'}>Create Contest</Typography>
                </Box>
                <Box>
                    <Typography variant={''} >Name</Typography>
                    <TextField sx={{marginBottom: 2}} size='small' fullWidth value={contest.name} onChange={({ target: { value } }) => setContest({ ...contest, name: value })} />
                    <Typography variant={''} >Description</Typography> 
                    <TextField sx={{marginBottom: 2}} size='medium' fullWidth value={contest.description} onChange={({ target: { value } }) => setContest({ ...contest, description: value })} />
                    <Typography variant={''} >Initial Credits</Typography>
                    <TextField sx={{marginBottom: 2}} size='small' type='number' fullWidth value={contest.credits} onChange={({ target: { value } }) => setContest({ ...contest, credits: value })} />
                    <Typography  variant={''} >Start At</Typography>
                    <Box sx={{width: '100%', display: 'flex',  paddingY: 1, gap: 2, alignItems: 'center'}}>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                            <DateTimePicker label="Start changer" value={contest.startAt} ampm={false} onChange={(value) => setContest({...contest, startAt: value})} inputFormat={"DD/MM/YY-HH:mm"} renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>
                    </Box>
                    <Typography sx={{marginTop: 3}} variant={''} >End At</Typography>
                    <Box sx={{width: '100%', display: 'flex',  paddingY: 1, gap: 2, alignItems: 'center'}}>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                            <DateTimePicker label="End changer" value={contest.endAt} ampm={false} onChange={(value) => setContest({...contest, endAt: value})} inputFormat={"DD/MM/YY-HH:mm"} renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{marginTop: 2}}>
                        <Typography variant={''} >Sport</Typography>
                        <FormControl fullWidth>
                            <Select value={contest.sport} label="Sport" onChange={(val) => setContest({...contest, sport: val.target.value})}>
                                <MenuItem value={'tennis'}>Tennis</MenuItem>
                                <MenuItem value={'soccer'}>Football</MenuItem>
                                <MenuItem value={'basketball'}>Basketball</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography sx={{marginTop: 3}} variant={''} >League(s)</Typography>
                        <FormControl sx={{ width: '100%' }}>
                            <Select disabled={loading} multiple value={contest.leagueId} onChange={(val) => onAddLeague(val.target.value)}>
                                {leagues.map((league) => ( <MenuItem key={league.name} value={league.name}>{league.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Box>
                    {contest.sport === 'tennis' && 
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography sx={{marginTop: 3}} variant={''} >Tournament(s)</Typography>
                            <FormControl sx={{ width: '100%' }}>
                                <Select multiple value={contest.tournamentId} onChange={(val) => onAddTournament(val.target.value)}>
                                    {tournaments.map((tournament) => (<MenuItem key={tournament.id} value={tournament.id}>{tournament.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant='contained' disabled={contest.leagueId.length === 0 || contest.name === ''} onClick={() => onCreate()}>Create</Button>
                </Box>
            </Paper>
        </Container>
    )
}