import axios from "axios";
import { useTokenContext } from "../context/token.context";
import { useEffect, useState } from "react";

export default function useAxios({
  url,
  method = "get",
  body,
  lazy = false,
  query,
}) {
  const { token, setToken } = useTokenContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const request = (_query = query, _body = body, _url = url) => {
    setLoading(true);
    if (_query && _query !== {}) {
      const queryStr = Object.keys(_query).reduce((str, key) => {
        if (str !== "?") str += `&`;
        str += `${key}=${_query[key]}`;
        return str;
      }, "?");
      _url += queryStr;
    }
    axios({
      url: _url,
      method,
      data: _body,
      // headers: { 'access-token': token }
    })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        //TODO 401
        setLoading(false);
        setError(error.data);
      });
  };

  useEffect(() => {
    if (method === "get" && !lazy) {
      request();
    }
  }, []);

  return { data, loading, error, request };
}
