import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Breadcrumbs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Loading from '../components/loading'
import SportSelect from '../components/sportSelect'
import { BOBaseURL } from '../constant'
import useAxios from '../hooks/axios.hook'

export default function LeaguesPage({ }) {
    const [sport, setSport] = useState('tennis')
    const { data: leagues, loading, request } = useAxios({ lazy: true })
    const history = useHistory()

    useEffect(() => {
        if (sport) request(null, null, `${BOBaseURL}sport/${sport}/leagues`)
    }, [sport])

    return (
        <>

            <Breadcrumbs sx={{ py: 1 }}>
                <Typography>Sport</Typography>
                <Typography color="text.primary">League</Typography>
            </Breadcrumbs>

            <SportSelect sport={sport} setSport={setSport} />

            <Paper sx={{ p: 2 }}>
                <Loading loading={loading}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {leagues?.map(league => (
                                <TableRow hover onClick={() => history.push(`leagues/${sport}/${league.name}`)}>
                                    <TableCell>{league.displayName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Loading>
            </Paper>
        </>
    )
}