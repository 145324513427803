import React, {useEffect, useState} from 'react'
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    TextField,
    Typography,
} from "@mui/material";
import moment from "moment";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";

export default function GiveUserAccess({user, open, onClose}) {
    const {request, loading, data} = useAxios({url: `${BOBaseURL}user/access/free`, method: 'post'})
    const [force, setForce] = useState(true)
    const [date, setDate] = useState(moment().add(7, 'day'))

    useEffect(() => {
        if (data) onClose()
    }, [data])

    const onRequest = () => {
        request({}, {expiredDate: date.format('YYYY-MM-DD'), list: [{userId: user.id, force}]})
    }

    const onChange = ({target: {value}}) => {
        setDate(moment(value))
    }

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} >
            <DialogTitle>Free access</DialogTitle>

            <DialogContent dividers={true}>
                <Box sx={{display: 'grid'}}>
                    <Typography variant={'caption'}>User</Typography>
                    <Typography>{user?.email ?? user?.name}</Typography>
                </Box>

                <Box sx={{display: 'grid', mt: 2}}>
                    <Typography variant={'caption'}>Choice the free access duration</Typography>
                    <TextField type="date" onChange={onChange} value={date.format('YYYY-MM-DD')}/>
                </Box>

                <Box sx={{display: 'grid', mt: 2}}>
                    <FormControlLabel  control={<Checkbox checked={force} onChange={({target: {checked}}) => setForce(checked)} />} label={'Bypass notification system'} />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant={'contained'} onClick={onRequest}>Give access</Button>
            </DialogActions>
        </Dialog>
    )
}