import React, {useEffect, useState} from 'react'
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, MenuItem,
    Select,
    Typography
} from "@mui/material";
import useAxios from "../../hooks/axios.hook";
import {LoadingButton} from "@mui/lab";
import {BOBaseURL} from "../../constant";

function RenderDialog({open, onClose}) {
    const [sport, setSport] = useState('BASKETBALL')
    const {request, data, loading} = useAxios({url: `${BOBaseURL}crawler/bookmaker/${sport}`, method: 'post'})

    const onSubmit = () => {
        request()
    }

    useEffect(() => {
        if (data) onClose()
    }, [data])

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Crawl market</DialogTitle>

            <DialogContent dividers>
                <Select fullWidth value={sport} onChange={({target: {value}}) => setSport(value)}>
                    <MenuItem value={"BASKETBALL"}>Basketball</MenuItem>
                    <MenuItem value={"SOCCER"}>Soccer</MenuItem>
                    <MenuItem value={"TENNIS"}>Tennis</MenuItem>
                </Select>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>CANCEL</Button>
                <LoadingButton variant={'contained'} loading={loading} onClick={onSubmit}>RUN</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default function CrawlMarkets({}) {
    const [open, setOpen] = useState(false)

    return (
        <Card sx={{m: 1}}>
            <RenderDialog open={open} onClose={() => setOpen(false)} />
            <CardContent>
                <Typography>Get Markets</Typography>
                <div>
                    <Button onClick={() => setOpen(true)} color={"primary"} variant={'contained'}>Run</Button>
                </div>
            </CardContent>
        </Card>
    )
}