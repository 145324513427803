import {Link, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {
    Breadcrumbs,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import useAxios from "../../hooks/axios.hook";
import {BOBaseURL} from "../../constant";
import React, {useEffect, useState} from "react";
import Loading from "../../components/loading";
import {LoadingButton} from "@mui/lab";
import CountryCodeSelect from "../../components/countryCodeSelect";

export default function TournamentPage({}) {
    const {id} = useParams()
    const {data, loading, error} = useAxios({url: `${BOBaseURL}tennis/tournament/${id}`, method: 'get'})
    const {data: updated, loading: updateLoading, error: updateError, request: update} = useAxios({url: `${BOBaseURL}tennis/tournament/${id}`, method: 'POST'})

    const [form, setForm] = useState(undefined)

    useEffect(() => {
        if (data) {
            setForm({
                displayName: data.displayName,
                surface: data.extra?.surface,
                level: data.extra?.level === undefined ? -1 : data.extra?.level,
                eventType: data.extra?.eventType,
                countryCode: data.extra?.countryCode,
            })
        }
    }, [data])

    const handleChange = (field, value) => {
        const tmp = {...form}
        tmp[field] = value
        console.log(tmp)
        setForm(tmp)
    }

    if (loading || !form) return <Loading />
    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb" sx={{py: 1}}>
                <Typography>Tennis</Typography>
                <Link
                    underline="hover"
                    color="inherit"
                    to="/tennis/tournament"
                >
                    Tournaments
                </Link>
                <Typography color="text.primary">{id}</Typography>
            </Breadcrumbs>

            <Paper sx={{
                p: 2,
                my: 2,
                display: 'flex',
                gap: 2
            }}>
                <TextField
                    value={form?.displayName}
                    onChange={({target: {value}}) => handleChange('displayName', value)}
                    label={'Display name'}
                    sx={{minWidth: '20%'}}
                />

                <TextField
                    value={form?.eventType}
                    disabled
                    onChange={({target: {value}}) => handleChange('eventType', value)}
                    label={'Event Type'}
                />

                <CountryCodeSelect
                    value={form?.countryCode}
                    sx={{minWidth: '20%'}}
                    onChange={value => handleChange('countryCode', value)} />

                <FormControl>
                    <InputLabel id="level">Level</InputLabel>
                    <Select
                        labelId="level"
                        value={parseInt(form?.level)}
                        onChange={({target: {value}}) => handleChange('level', value)}
                        label={'Level'}
                    >
                        <MenuItem value={-1}>Other</MenuItem>
                        <MenuItem value={0}>Qualification</MenuItem>
                        <MenuItem value={250}>ATP/WTA 250</MenuItem>
                        <MenuItem value={500}>ATP/WTA 500</MenuItem>
                        <MenuItem value={1000}>ATP/WTA 1000</MenuItem>
                        <MenuItem value={2000}>Grand Slam</MenuItem>
                    </Select>
                </FormControl>
            </Paper>

            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <LoadingButton onClick={() => update({}, form)} variant={'contained'} loading={updateLoading}>Update</LoadingButton>
            </Box>
        </Box>
    )
}