import React, {useEffect} from 'react'
import countryCode from '../assets/data/countryCode.json'
import {Autocomplete} from "@mui/lab";
import {TextField} from "@mui/material";
import Flags from 'country-flag-icons/react/3x2'

export default function CountryCodeSelect({value, onChange, sx}) {

    return (
        <Autocomplete
            value={countryCode.find(elem => elem.code === value)}
            sx={sx}
            size='small'
            onChange={(event, newValue) => {
                onChange(newValue.code)
            }}
            clearOnBlur
            autoFocus
            handleHomeEndKeys
            options={countryCode}
            renderOption={(props, option) => {
                const Flag = Flags[option.code]
                if (Flag)
                    return <li {...props}><Flag style={{width: 15, marginRight: 5}}/>{option.name}</li>
                return <li {...props}>{option.name}</li>

            }}
            getOptionLabel={(option) => option.name}
            freeSolo
            renderInput={(params) => (
                <TextField size='small' label={'Region'} autoCorrect="off" autoFocus {...params} placeholder={'France'} />
            )}
        />    )
}