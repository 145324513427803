import React from 'react'
import {Box, CircularProgress} from "@mui/material";

export default function Loading({loading, children}) {
    return (
        loading ?
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', paddingY: 2}}><CircularProgress /></Box>
            :
            children
    )
}